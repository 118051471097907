"use client";

import Image from "next/image";
import Link from "next/link";

import { Button } from "@/components/ui/button";

import Logo from "../../public/logo.png";
import { Login } from "../app/navigate";

export default function Header() {
  return (
    <header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/5">
      <div className="container flex h-14 max-w-screen-2xl items-center">
        <Link href="/" className="mr-4 flex items-center space-x-2 lg:mr-6">
          <Image src={Logo} alt="Company Logo" width={200} height={200} />
        </Link>
        <div className="flex flex-1 items-center justify-end md:justify-between">
          <nav aria-label="Global" className="hidden md:block">
            <ul className="flex items-center gap-6 text-sm"></ul>
          </nav>

          <div className="flex items-center gap-4">
            <Button
              className="bg-purple-800 hover:bg-purple-700"
              onClick={Login}>
              Login
            </Button>

            <Button
              variant="secondary"
              className="text-purple-600 hover:text-purple-600/75"
              asChild>
              <Link href="/contact">Contact</Link>
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
}
